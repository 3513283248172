.rcsiBackLinkWhite {
    color: #999;
}

fieldset {
    border-radius: 0 !important;
}

.rcsiTextField {
    border-radius: 0 !important;
}

.rcsiSelectField {
    border-radius: 0 !important;
}

input[type='checkbox'].form-check-input,
input[type='radio'] {
    border-radius: 0 !important;
    background-color: white !important;
}

.wordWrap {
    word-wrap: break-word;
}

.btnAcceptCookie:hover,
.btnCookieSettings:hover,
.btnCookiePolicy:hover {
    opacity: .7;
}